import api from './index.js'

export const fetchPartners = async (
  currentPage,
  pageSize,
  params,
  sortColumn,
  sortDirection
) => {
  try {
    const queryParams = new URLSearchParams(params).toString() // Convert params object to query string
    const response = await api.get(
      `/admin/partner?pageNumber=${currentPage}&pageSize=${pageSize}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&${queryParams}`
    )

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to fetch partners')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to fetch partners')
  }
}

export const fetchPartnersAdjustment = async (
  params,
) => {
  try {
    const queryParams = new URLSearchParams(params).toString() // Convert params object to query string
    const response = await api.get(
      `/admin/partner/adjustment?&${queryParams}`
    )

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to fetch partners')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to fetch partners')
  }
}

export const fetchPartnersInfo = async (
  params,  
  currentPage = 0,
  pageSize = 0,
) => {
  try {
    const queryParams = new URLSearchParams(params).toString() // Convert params object to query string
    const response = await api.get(
      `/admin/partner/info?pageNumber=${currentPage ?? 0}&pageSize=${pageSize ?? 0}&${queryParams}`
    )

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to fetch partners')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to fetch partners')
  }
}

export const fetchPartnerMembersInfo = async (
  params,
  currentPage = 0,
  pageSize = 0,
  sortColumn,
  sortDirection
) => {
  try {
    const queryParams = new URLSearchParams(params).toString() // Convert params object to query string
    const response = await api.get(
      `/admin/partner/info/member?pageNumber=${currentPage ?? 0}&pageSize=${pageSize ?? 0}&${queryParams}&sortColumn=${sortColumn}&sortDirection=${sortDirection}`
    )

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to fetch partners member')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to fetch partners member')
  }
}

export const fetchPartnersLossInfo = async (
  params,
) => {
  try {
    const queryParams = new URLSearchParams(params).toString() // Convert params object to query string
    const response = await api.get(
      `/admin/partner/info/loss?&${queryParams}`
    )

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to fetch partners')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to fetch partners')
  }
}

export const fetchPartnersBetInfo = async (
  params,
) => {
  try {
    const queryParams = new URLSearchParams(params).toString() // Convert params object to query string
    const response = await api.get(
      `/admin/partner/info/bet?&${queryParams}`
    )

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to fetch partners')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to fetch partners')
  }
}

export const fetchPartnerTree = async () => {
  try {
    const response = await api.get('/admin/partner/tree')

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to fetch partner tree')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to fetch partner tree')
  }
}

export const fetchPartner = async id => {
  try {
    const response = await api.get(`/admin/partner/${id}`)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to fetch partner')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to fetch partner')
  }
}

export const updatePartner = async (id, partnerData) => {
  try {
    const response = await api.put(`/admin/partner/${id}`, partnerData)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to update partner')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to update partner')
  }
}

export const createPartnerMember = async partnerData => {
  try {
    const response = await api.post('/admin/partner/member', partnerData)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to create partner')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to create partner')
  }
}

export const createPartner = async partnerData => {
  try {
    const response = await api.post('/admin/partner', partnerData)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to create partner')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to create partner')
  }
}

export const deletePartner = async id => {
  try {
    const response = await api.delete(`/admin/partner/${id}`)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to delete partner')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to delete partner')
  }
}



export const updatePartnerWallet = async (id, userData) => {
  try {
    const response = await api.put(`/admin/partner/wallet/${id}`, userData)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to update partner')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to update partner')
  }
}

export const updatePartnerEgg = async (id, userData) => {
  try {
    const response = await api.put(`/admin/partner/egg/${id}`, userData)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to update partner')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to update partner')
  }
}

export const updatePartnerLoss = async (id, userData) => {
  try {
    const response = await api.put(`/admin/partner/loss/${id}`, userData)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to update partner')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to update partner')
  }
}


export const fetchPartnerConfig = async id => {
  try {
    const response = await api.get(`/admin/partner/config/${id}`)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to fetch parnter config')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to fetch parnter config')
  }
}

export const updatePartnerConfig = async (id, userData) => {
  try {
    const response = await api.put(`/admin/partner/config/${id}`, userData)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to update parnter config')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to update parnter config')
  }
}