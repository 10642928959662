import React from 'react';

const formatNumber = (number, decimalPoints, includeCommas) => {
  let formattedNumber = decimalPoints >= 0 ? number.toFixed(decimalPoints) : Math.round(number).toLocaleString();
  if (includeCommas) {
    formattedNumber = formattedNumber.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }
  return formattedNumber;
};

const NumberDisplay = ({
  value,
  positiveColor = 'blue',
  negativeColor = 'red',
  showPrefix = false,
  alwaysShowNegativeSign = false, // New attribute
  suffix = '',
  decimalPoints = 0,
  includeCommas = true,
  usePositiveColor = false,
  useNegativeColor = false,
  useBold = false,
  showBig = false, // New attribute
}) => {  
  const textStyle = {
    fontWeight: useBold ? 'bold' : 'normal',
    fontSize: showBig ? '1.25em' : '1em', // Adjust font size based on `showBig`
  };
  
  // Check if value is not a number or doesn't exist
  if (isNaN(value)) {
    return <span style={{ color: 'gray', ...textStyle }}></span>;
  }

  const isNegative = value < 0;
  const prefix = showPrefix
    ? value > 0
      ? '+'
      : isNegative
      ? '-'
      : ''
    : alwaysShowNegativeSign && isNegative
    ? '-'
    : '';
  
  const color = value >= 0 ? (usePositiveColor ? positiveColor : null) : (useNegativeColor ? negativeColor : null);

  return (
    <span style={{ color, ...textStyle }}>
      <span>{prefix}{formatNumber(Math.abs(value), decimalPoints, includeCommas)}{suffix}</span>
    </span>
  );
};

export default NumberDisplay;
