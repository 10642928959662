import React, { lazy, useState, useEffect, Suspense } from 'react'
import './HelpCenter.css'
import { Layout } from 'components'
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Container
} from 'reactstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux";
import { userSelector } from "features/auth/authSelectors";

const HelpCenterList = lazy(() => import('./HelpCenterList'))
const HelpCenterTemplateList = lazy(() => import('../HelpCenterTemplate/HelpCenterTemplateList'))

function HelpCenter(props) {
  const currentUser = useSelector(userSelector);
  const { search, pathname } = useLocation()
  const location = useLocation()
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState('list')

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
      navigate(`${pathname}?tab=${tab}`)
    }
  }

  useEffect(() => {
    const tabParam = new URLSearchParams(search).get('tab')
    const initialTab = tabParam || 'list'
    setActiveTab(initialTab)
  }, [location])
  return (
    <Layout>
      <Container className='page-content' fluid style={{ padding: 30 }}>
        <Row>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={activeTab === 'list' ? 'sub-link active' : ''}
                onClick={() => toggleTab('list')}
              >
                고객센터 목록
              </NavLink>
            </NavItem>
            {
              (currentUser?.isHeadQuarter || currentUser?.isRoot) &&
              <NavItem>
                <NavLink
                  className={activeTab === 'template' ? 'sub-link active' : ''}
                  onClick={() => toggleTab('template')}
                >
                  고객센터 템플릿
                </NavLink>
              </NavItem>

            }
          </Nav>
        </Row>
        <Row>
          <TabContent className='partner-tab-content' activeTab={activeTab}>
            {activeTab === 'list' && (
              <TabPane tabId='list'>
                <Suspense fallback={<div>Loading...</div>}>
                  <HelpCenterList />
                </Suspense>
              </TabPane>
            )}
            {activeTab === 'template' && (
              <TabPane tabId='template'>
                <Suspense fallback={<div>Loading...</div>}>
                  <HelpCenterTemplateList />
                </Suspense>
              </TabPane>
            )}
          </TabContent>
        </Row>
      </Container>
    </Layout>
  )
}

export default HelpCenter
