import React, { useState, useEffect, useRef } from 'react'
import {
  Container,
  Table,
  Input,
  Button,
  Navbar
} from 'reactstrap'
import { Editor } from 'components'
import { showAlert, showSuccess } from 'utils/toast'
import { Loader } from 'components'
import { MdClose } from "react-icons/md"
import { createHelpCenterTemplate } from 'api/helpCenterTemplateApi'
import colors from 'assets/colors'

function HelpCenterTemplateCreate (props) {
  const editorRef = useRef()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  const [editorKey, setEditorKey] = useState(Date.now())
  const [helpCenterTemplate, setHelpCenterTemplate] = useState({
    name: '',
    content: ''
  })

  useEffect(() => {}, [])

  const refresh = () => {
    setHelpCenterTemplate({
      name: '',
      content: ''
    })
    setEditorKey(Date.now())
    setError(null)
  }

  const handleInputChange = event => {
    const { name, value, type } = event.target

    let updatedValue = value

    // Check if the input type is a radio button
    if (type === 'radio') {
      if (value === 'true') {
        updatedValue = true // Set to true if value is 'true'
      } else if (value === 'false') {
        updatedValue = false // Set to false if value is 'false'
      }
    }

    if (type === 'number') {
      updatedValue = Number(value)
    }

    const updatedHelpCenterTemplate = { ...helpCenterTemplate }
    const nameParts = name.split('.')
    let currentHelpCenterTemplate = updatedHelpCenterTemplate
    for (let i = 0; i < nameParts.length - 1; i++) {
      if (!currentHelpCenterTemplate[nameParts[i]]) {
        currentHelpCenterTemplate[nameParts[i]] = {}
      }
      currentHelpCenterTemplate = currentHelpCenterTemplate[nameParts[i]]
    }
    currentHelpCenterTemplate[nameParts[nameParts.length - 1]] = updatedValue
    setHelpCenterTemplate(updatedHelpCenterTemplate)
  }

  const handleClose = () => {
    if (typeof props.onClose === "function") props.onClose()
  }

  const handleSave = async () => {
    setIsLoading(true)

    try {
      const content = editorRef?.current?.getContent()

      const { success, message } = await createHelpCenterTemplate({...helpCenterTemplate, content: content})

      if (!success) throw new Error(message)

      showSuccess('생성되었습니다!')
      refresh()
    } catch (error) {
      console.error('Failed to update helpCenterTemplate:', error)
      showAlert(error.message)
    }

    setIsLoading(false)
  }

  return (
    <>
      <Navbar
        style={{ backgroundColor: colors.primary, padding: '1rem' }}
        expand='md'
      >
        <h5 className='m-0'>{'고객센터 템플릿 작성'}</h5>{' '}
        {/* Use the label based on the active tab */}
        <div className="text-right">
          <MdClose
            className="clickable-icon"
            size={18}
            onClick={() => props.onClose && props.onClose()}
          />
        </div>
      </Navbar>

      <Container className='page-content' style={{ paddingBottom: 100 }}>
        {isLoading ? (
          <Loader />
        ) : (
          <Table bordered style={{ marginTop: '2rem' }}>
            <tbody className='align-items-center'>
              <tr>
                <td className='form-header text-center w-200p' xs={3}>
                  제목
                </td>
                <td xs={9}>
                  <Input
                    type='text'
                    className='form-control-sm input-sm'
                    placeholder='제목'
                    name='name'
                    value={helpCenterTemplate?.name}
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td className='form-header text-center' xs={3}>
                  내용
                </td>
                <td xs={9}>
                  <Editor
                    ref={editorRef}
                    key={editorKey}
                    value={helpCenterTemplate?.content ?? ''}
                    onContentChange={value =>
                      handleInputChange({
                        target: { name: 'content', value: value }
                      })
                    }
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        )}

        {/* Action bar */}
        <div className={`action-bar-container show`}>
          <div className='action-bar'>
            <div className='left-actions'></div>
            <div className='right-actions'>
              <Button className="me-2" color="warning" onClick={handleClose}>
                닫기
              </Button>
              {/* Right-aligned action buttons */}
              <Button className='me-2' color='primary' onClick={handleSave}>
                저장
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default HelpCenterTemplateCreate
