import React, { useState, useEffect } from 'react'
import './HelpCenterTemplate.css'
import {
  Input,
  Table,
  Button,
  Alert,
  Modal
} from 'reactstrap'
import colors from 'assets/colors'
import dayjs from 'dayjs'
import { Loader } from 'components'
import { FaSortUp, FaSortDown, FaSyncAlt } from 'react-icons/fa'
import { DatePicker } from 'reactstrap-date-picker'
import { deleteHelpCenterTemplate, fetchHelpCenterTemplates, updateHelpCenterTemplate } from 'api/helpCenterTemplateApi'
import { showAlert, showSuccess } from 'utils/toast'
import NewWindow from 'react-new-window'
import PaginationComponent from "components/PaginationComponent"
import HelpCenterTemplateCreate from './HelpCenterTemplateCreate'
import HelpCenterTemplateEdit from './HelpCenterTemplateEdit'

const columns = [
  { key: 'checkbox', label: '', sortable: false },
  { key: 'name', label: '제목', sortable: true },
  // { key: 'content', label: '내용', width: '40%', sortable: true },
  // { key: 'createdAt', label: '작성일', sortable: true },
]

function HelpCenterTemplateList(props) {
  const [currentCreateWindow, setCurrentCreateWindow] = useState(null)
  const [currentViewWindow, setCurrentViewWindow] = useState(null)

  // Partner data
  const [helpCenterTemplates, setHelpCenterTemplates] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  // Pagination
  const pagesToShow = 10
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [totalPages, setTotalPages] = useState(1)
  const [totalCount, setTotalCount] = useState(1)

  // Sorting
  const [sortColumn, setSortColumn] = useState('createdAt')
  const [sortDirection, setSortDirection] = useState('desc')

  // Selection
  const [hoveredRow, setHoveredRow] = useState(null)
  const [isCheckedAll, setIsCheckedAll] = useState(false)
  const [checkedItems, setCheckedItems] = useState([])

  // Create Modal
  const [createOpen, setCreateOpen] = useState(false)

  const [selectedItem, setSelectedItem] = useState(null)

  const [editModal, setEditModal] = useState(false);
  const [createModal, setCreateModal] = useState(false);

  const [params, setParams] = useState({
    searchText: ''
  })

  const resetParams = async () => {
    setParams({
      searchText: ''
    })
  }

  const refresh = async () => {
    // Refresh the partner list
    fetchData()
    setError(null)

    // Clear the checked items
    setCheckedItems([])
    setIsCheckedAll(false)
  }

  const fetchData = async (applyParams = true) => {
    setIsLoading(true)
    try {
      const { success, message, data } = await fetchHelpCenterTemplates(
        currentPage,
        pageSize,
        applyParams ? params : {},
        sortColumn,
        sortDirection
      )

      if (!success) throw new Error(message)
      setHelpCenterTemplates(data.helpCenterTemplates)
      setTotalPages(data.meta.totalPages)
      setTotalCount(data.meta.count)
    } catch (error) {
      console.error(error)
      setError(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [currentPage, pageSize, sortColumn, sortDirection])

  const handleParamsChange = event => {
    const { name, value, type } = event.target

    let updatedValue = value

    // Check if the input type is a radio button
    if (type === 'radio') {
      if (value === 'true') {
        updatedValue = true // Set to true if value is 'true'
      } else if (value === 'false') {
        updatedValue = false // Set to false if value is 'false'
      }
    }

    if (type === 'number') {
      updatedValue = Number(value)
    }

    const updatedParams = { ...params }
    const nameParts = name.split('.')
    let currentParams = updatedParams
    for (let i = 0; i < nameParts.length - 1; i++) {
      if (!currentParams[nameParts[i]]) {
        currentParams[nameParts[i]] = {}
      }
      currentParams = currentParams[nameParts[i]]
    }
    currentParams[nameParts[nameParts.length - 1]] = updatedValue
    setParams(updatedParams)
  }

  const handleFormSubmit = e => {
    e.preventDefault()
    fetchData(true)
  }

  const handlePageClick = page => setCurrentPage(page)

  const handlePageSizeChange = e => {
    setCurrentPage(1)
    setPageSize(parseInt(e.target.value))
  }

  const handleSort = column => {
    if (sortColumn === column) {
      setSortDirection(prevDirection =>
        prevDirection === 'asc' ? 'desc' : 'asc'
      )
    } else {
      setSortColumn(column)
      setSortDirection('asc')
    }
  }

  const handleCheckAll = () => {
    setIsCheckedAll(!isCheckedAll)
    setCheckedItems(isCheckedAll ? [] : helpCenterTemplates.map(item => item._id))
  }

  const handleCheckItem = itemId => {
    if (checkedItems.includes(itemId)) {
      setCheckedItems(prevCheckedItems =>
        prevCheckedItems.filter(id => id !== itemId)
      )
    } else {
      setCheckedItems(prevCheckedItems => [...prevCheckedItems, itemId])
    }
  }

  const renderColumnLabel = column => column.label

  const handleHelpCenterTemplateClick = mail => {
    if(typeof props.onClick === 'function') {
      return props.onClick(mail)
    }
    setEditModal(true)
    setSelectedItem(mail)
  }

  const handleCheckDelete = async () => {
    if (!window.confirm('삭제하시겠습니까?')) return
    if (!checkedItems.length) {
      showAlert('삭제할 대상을 선택해주세요!')
    }

    setIsLoading(true)

    try {
      const selectedHelpCenterTemplateIds = checkedItems
      if (selectedHelpCenterTemplateIds.length === 0) {
        showAlert('대상을 선택해주세요!')
        return
      }

      const promises = selectedHelpCenterTemplateIds.map(async helpCenterTemplateId => {
        const { data, message, success } = await deleteHelpCenterTemplate(helpCenterTemplateId)
        if (!success) {
          throw new Error(message)
        }
      })

      await Promise.all(promises)

      showSuccess('삭제되었습니다!')

      refresh()
    } catch (error) {
      console.log(error)
      console.error('Failed to delete help-center-template:', error)
      showAlert(error.message)
    }

    setIsLoading(false)
  }

  return (
    <div style={{ paddingBottom: 100 }}>
      {isLoading && <Loader />}

      <Table>
        <tbody className='align-items-center'>
          <tr>
            <td className='form-header w-100p align-middle text-center' xs={3}>
              검색
            </td>
            <td xs={9}>
              <div className='d-flex align-items-center'>

                <div className='me-3'>
                  <Input
                    type="text"
                    name="searchText"
                    value={params?.searchText}
                    onChange={handleParamsChange}
                    className="form-control form-control-sm"
                    placeholder="검색어"
                  />
                </div>

                <Button
                  style={{
                    backgroundColor: colors.primary,
                    borderColor: colors.primary
                  }}
                  onClick={handleFormSubmit}
                  color='primary'
                  size='sm'
                >
                  검색
                </Button>
                &nbsp;
                <Button
                  onClick={resetParams}
                  color="warning"
                  size="sm"
                >
                  초기화
                </Button>
              </div>
            </td>
          </tr>
        </tbody>
      </Table>

      <>
        <div className='search-header'>
          <div>
            {`검색된 공지사항 템플릿: `}
            <span className='text-danger'>{totalCount.toLocaleString()}</span>개
          </div>
          <div className='select-wrapper'>
            <div className='refresh'>
              <button className='btn btn-link' onClick={refresh}>
                <FaSyncAlt style={{ color: colors.primary }} />
              </button>
            </div>

            <select
              className='form-control'
              onChange={handlePageSizeChange}
              value={pageSize}
            >
              <option value='5'>5개씩 보기</option>
              <option value='10'>10개씩 보기</option>
              <option value='20'>20개씩 보기</option>
              <option value='50'>50개씩 보기</option>
            </select>
          </div>
        </div>
        {error && <Alert color='danger'>{error?.message}</Alert>}

        <Table bordered responsive>
          <thead>
            <tr>
              {columns
              .filter(column => {
                if(typeof props.onClick === 'function') {
                  return column.key !== 'checkbox'
                }
                return true
              })
              .map(column => (
                <th
                  key={column.key}
                  className='fixed-width'
                  width={column.width ?? 'auto'}
                  onClick={() =>
                    column.sortable ? handleSort(column.key) : {}
                  }
                >
                  {column.key === 'checkbox' && (
                    <input
                      type='checkbox'
                      checked={isCheckedAll}
                      onChange={handleCheckAll}
                    />
                  )}
                  {renderColumnLabel(column)}
                  {column.sortable && sortColumn === column.key && (
                    <>
                      {sortDirection === 'asc' ? <FaSortUp /> : <FaSortDown />}
                    </>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className='text-center'>
            {helpCenterTemplates.length === 0 ? (
              <tr>
                <td className='text-center' colSpan={columns.length}>
                  결과없음
                </td>
              </tr>
            ) : (
              helpCenterTemplates.map((item, index) => (
                <tr
                  key={index}
                  className={index === hoveredRow ? 'hovered' : ''}
                  onMouseEnter={() => setHoveredRow(index)}
                  onMouseLeave={() => setHoveredRow(null)}
                >
                  {columns
                  .filter(column => {
                    if(typeof props.onClick === 'function') {
                      return column.key !== 'checkbox'
                    }
                    return true
                  })
                  .map(column => (
                    <td
                      key={column.key}
                      role='button'
                      onClick={() => column.key !== 'checkbox' && handleHelpCenterTemplateClick(item)}
                    >
                      {column.key === 'checkbox' && (
                        <input
                          type='checkbox'
                          checked={checkedItems.includes(item?._id)}
                          onChange={() => handleCheckItem(item?._id)}
                        />
                      )}

                      {column.key === 'name' && (
                        <span>
                          {item?.name}
                        </span>
                      )}

                      {/* {column.key === 'content' && (
                        <div 
                        style={{ 
                          overflow: 'hidden', 
                          textOverflow: 'ellipsis', 
                          whiteSpace: 'nowrap',
                          maxWidth: '200px', // or any desired width
                          maxHeight: '50px'
                        }}
                        dangerouslySetInnerHTML={{ __html: item?.content }}
                      />
                      )} */}

                      {column.key === 'createdAt' && (
                        <span>
                          {`${dayjs(item?.access?.createdAt).format(
                            'YYYY-MM-DD HH:mm:ss'
                          )}`}
                        </span>
                      )}
                    </td>
                  ))}
                </tr>
              ))
            )}
          </tbody>
        </Table>
        <PaginationComponent
          totalPages={totalPages}
          currentPage={currentPage}
          pagesToShow={pagesToShow}
          handlePageClick={handlePageClick}
        />
      </>
      {/* Action bar */}
      {
        !props?.side &&
        <div className={`action-bar-container show`}>
          <div className='action-selected'>
            총 {checkedItems.length.toLocaleString()}개 선택됨
          </div>
          <div className='action-bar'>
            <div className='left-actions'>
              <Button className='me-2' color='danger' onClick={handleCheckDelete}>
                삭제
              </Button>
            </div>
            <div className='right-actions'>
              {/* Right-aligned action buttons */}
              <Button
                color='primary'
                onClick={() => {
                  setCreateModal(true)
                }}
              >
                고객센터 템플릿 작성
              </Button>
            </div>
          </div>
        </div>
      }
      <Modal
        size={"xl"}
        isOpen={editModal}
        toggle={() => setEditModal(!editModal)}
        onClosed={refresh}
        backdrop="static"
      >
        <HelpCenterTemplateEdit
          targetId={selectedItem?._id}
          onClose={() => setEditModal(false)}
        />
      </Modal>
      <Modal
        size={"xl"}
        isOpen={createModal}
        toggle={() => setCreateModal(!createModal)}
        onClosed={refresh}
        backdrop="static"
      >
        <HelpCenterTemplateCreate
          targetId={selectedItem?._id}
          onClose={() => setCreateModal(false)}
        />
      </Modal>

      {/* {createOpen && (
        <NewWindow
          url={`/help-center-template/create`}
          key={'create'}
          title={'공지사항 작성'}
          features={{
            width: 1200,
            height: 900,
            left: 10,
            top: 0
          }}
          copyStyles={false}
          onOpen={window => {
            setCurrentCreateWindow(window)
          }}
          onBlock={() => showAlert('팝업 사용을 허용해주세요!')}
          onUnload={() => {
            setCurrentCreateWindow(null)
            setCreateOpen(false)
            refresh()
          }}
          closeON
        />
      )}

      {item &&
        helpCenterTemplates.map(item => {
          let url = `/help-center-template/edit/${item._id}`
          return (
            item._id === item._id && (
              <NewWindow
                key={item._id}
                title={item.name}
                features={{
                  width: 1200,
                  height: 900,
                  left: 10,
                  top: 0
                }}
                copyStyles={false}
                onOpen={window => {
                  setCurrentViewWindow(window)
                }}
                url={url}
                onBlock={() => showAlert('팝업 사용을 허용해주세요!')}
                onUnload={() => {
                  setCurrentViewWindow(null)
                  setSelectedItem(null)
                  refresh()
                }}
              />
            )
          )
        })} */}
    </div>
  )
}

export default HelpCenterTemplateList
