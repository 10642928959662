import React, { useState, useEffect, useRef } from "react"
import {
  Container,
  Table,
  Label,
  Input,
  Button,
  Navbar,
} from "reactstrap"
import { Editor } from "components"
import { showAlert, showSuccess } from "utils/toast"
import { Loader } from "components"
import { createMail } from "api/mailApi"
import { fetchDefaultSiteConfig } from "api/siteConfigApi"
import { MdClose } from "react-icons/md";

import colors from "assets/colors"
import { useSearchParams } from "react-router-dom"

function MailCreate(props) {
  const editorRef = useRef()
  const [searchParams] = useSearchParams()
  // const type = searchParams.get("type")
  const type = (props?.type === 'partnerReceived' || props?.type === 'partnerSent') ? 'partner' : 'member'
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [editorKey, setEditorKey] = useState(Date.now())
  const [mail, setMail] = useState({
    type: type,
    category: "individual",
    partner: "",
    individual: props?.targetUsername ?? "",
    grade: [],
    name: "",
    content: "",
  })

  const [gradeConfigs, setGradeConfigs] = useState([])
  const fetchGradeConfigs = async () => {
    setIsLoading(true)
    try {
      const { success, message, data } = await fetchDefaultSiteConfig()
      // console.log(data)
      if (!success) throw new Error(message)
      if (data) {
        setGradeConfigs(data?.grades)
      }
    } catch (error) {
      console.error(error)
      setError(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchGradeConfigs()
  }, [])

  const refresh = () => {
    setMail({
      type: type,
      category: "individual",
      partner: "",
      individual: props?.targetUsername ?? "",
      grade: [],
      name: "",
      content: "",
    })
    setEditorKey(Date.now())
  }

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target

    let updatedValue = value

    // Check if the input type is a radio button
    if (type === "radio") {
      if (value === "true") {
        updatedValue = true // Set to true if value is 'true'
      } else if (value === "false") {
        updatedValue = false // Set to false if value is 'false'
      }
    }

    if (type === "number") {
      updatedValue = Number(value)
    }

    if (type === "checkbox") {
      const currentArray = mail[name] || []
      if (checked) {
        updatedValue = [...currentArray, value]
      } else {
        updatedValue = currentArray.filter((item) => item !== value)
      }
    }

    const updatedConfig = { ...mail }
    const nameParts = name.split(".")
    let currentConfig = updatedConfig
    for (let i = 0; i < nameParts.length - 1; i++) {
      if (!currentConfig[nameParts[i]]) {
        currentConfig[nameParts[i]] = {}
      }
      currentConfig = currentConfig[nameParts[i]]
    }

    if (name.startsWith("serial")) {
      const subKey = nameParts[2] // Extract the sub-key (condition or reward)
      currentConfig[subKey] = updatedValue // Update the corresponding value in the serial array
    } else {
      currentConfig[nameParts[nameParts.length - 1]] = updatedValue
    }

    setMail(updatedConfig)
  }

  const handleSave = async () => {
    if(!mail.name) {
      showAlert("제목을 입력해주세요")
      return
    }
    
    setIsLoading(true)
    try {
      const content = editorRef?.current?.getContent()
      const { success, message } = await createMail({
        ...mail,
        content: content,
      })
      if (!success) throw new Error(message)

      showSuccess("쪽지를 보냈습니다")
      refresh()

      if (typeof props.onClose === "function") props.onClose()
    } catch (error) {
      console.error("Failed to update user mail:", error)
      showAlert(error.message)
    }

    setIsLoading(false)
  }

  return (
    <>
      <Navbar
        style={{ backgroundColor: colors.primary, padding: "1rem" }}
        expand="md"
      >
        <h5 className="m-0">
          {type === "member" ? "회원" : "파트너"} {"쪽지쓰기"}
        </h5>{" "}
        <div className="text-right">
          <MdClose
            className="clickable-icon"
            size={18}
            onClick={() => props.onClose && props.onClose()}
          />
        </div>
        {/* Use the label based on the active tab */}
      </Navbar>

      <Container className="page-content" style={{ paddingBottom: 100 }}>
        {isLoading ? (
          <Loader />
        ) : (
          <Table bordered style={{ marginTop: "2rem" }}>
            <tbody className="align-items-center">
              <tr>
                <td className="form-header text-center align-middle w-200p">
                  구분
                </td>
                <td>
                  <Input
                    type="select"
                    className="input-sm"
                    name="category"
                    value={mail?.category}
                    onChange={handleInputChange}
                  >
                    <option value="individual">개별</option>
                    {type === "member" && <option value="grade">등급별</option>}
                    {/* {type === "partner" && (
                      <option value="children">파트너별</option>
                    )} */}
                    {type === "member" && <option value="all">전체</option>}
                  </Input>
                </td>
              </tr>
              <tr>
                <td className="form-header text-center align-middle">수신</td>
                <td>
                  <div className="me-3 d-flex align-items-center ">
                    {mail.category === "individual" ? (
                      <Input
                        type="text"
                        className="form-control-sm input-sm"
                        placeholder={`${
                          type === "member" ? "회원" : "파트너"
                        } 아이디`}
                        name="individual"
                        value={mail?.individual}
                        onChange={handleInputChange}
                      />
                    ) : mail.category === "grade" ? (
                      gradeConfigs.map((grade, index) => (
                        <div
                          key={index}
                          className="form-check me-2 d-flex align-items-center"
                        >
                          <Input
                            type="checkbox"
                            id={`levelCheckbox${grade.grade}`}
                            name="grade"
                            value={grade.grade}
                            onChange={handleInputChange}
                            style={{ width: 20, height: 20, marginRight: 5 }}
                            className="form-check-input"
                          />
                          <Label for={`levelCheckbox${grade.grade}`} check>
                            {`${grade.grade}레밸`}
                          </Label>
                        </div>
                      ))
                    ) : mail.category === "children" ? (
                      <Input
                        type="text"
                        className="form-control-sm input-sm"
                        placeholder="파트너아이디"
                        name="partner"
                        value={mail?.partner}
                        onChange={handleInputChange}
                      />
                    ) : (
                      <span>전체회원</span>
                    )}
                  </div>
                </td>
              </tr>
              <tr>
                <td className="form-header text-center align-middle w-200p">
                  제목
                </td>
                <td>
                  <Input
                    type="text"
                    className="form-control-sm input-sm"
                    placeholder="제목"
                    name="name"
                    value={mail?.name}
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td className="form-header text-center align-middle w-200p">
                  내용
                </td>
                <td>
                  <Editor
                    ref={editorRef}
                    key={editorKey}
                    value={mail?.content ?? ""}
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        )}

        {/* Action bar */}
        <div className={`action-bar-container show`}>
          <div className="action-bar">
            <div className="left-actions"></div>
            <div className="right-actions">
              {/* Right-aligned action buttons */}
              <Button
                className="me-2"
                color="warning"
                onClick={() => {
                  if (typeof props.onClose === "function") props.onClose();
                }}
              >
                닫기
              </Button>
              <Button className="me-2" color="primary" onClick={handleSave}>
                보내기
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default MailCreate
