//import socket from '../socket' // Import the Socket.IO connection module
import io from 'socket.io-client'
import {
  socketConnect,
  socketDisconnect,
  socketReceiveData
} from '../features/socket/socketActions'
import { toast } from 'react-toastify'
import { playAlarmSound, speakText } from 'utils/audio'
import defaultAlarmSound from 'assets/alarm/alarm-example.mp3'
import EventAlarm from 'components/EventAlarm'
import { statusRequest } from 'features/status/statusActions'
import { statusThunk } from './statusThunks'
import { getPublicUrl, getSocketUrl } from 'libs/functions'
// const PUBLIC_URL =
//   process.env.REACT_APP_PUBLIC_URL || 'http://localhost:4000/static'

let socket
// const WSS_URL = process.env.REACT_APP_SOCKET_URL || 'http://localhost:4000/admin'

const WSS_URL = getSocketUrl()
const PUBLIC_URL = getPublicUrl()

const toastOptions = {
  position: "bottom-right",
  autoClose: true,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark"     
}

// Thunk action to emit a socket event
export const emitSocketEvent = eventData => async dispatch => {
  try {
    if (socket) {
      // socket.emit('event:alarm', eventData)
      // socket.emit('message', eventData)
    }
  } catch (error) {
    console.error('Socket event emission failed:', error)
  }
}

// Thunk action to connect to the Socket.IO server
export const connectSocket = () => {
  return (dispatch, getState) => {
    const { auth } = getState()

    console.log(`${WSS_URL}-${auth?.user?._id}`)
    socket = io(`${WSS_URL}-${auth?.user?._id}`, {
      autoConnect: false,
      withCredentials: true,      
      transports: ["websocket"],
      query: {
        type: 'admin'
      }
    })

    socket.connect()

    dispatch(socketConnect(socket))

    socket.on('message', async data => {
      // const { config } = getState()
      console.log('socket connected! to ')
      console.log(`${WSS_URL}/${auth?.user?.hq}`)
      // await playAlarmSound(defaultAlarmSound)
    })

    socket.on('event:alarm', async data => {
      console.log('alarm:receive:client', { data })
      const { type, id } = data
      const { auth, config } = getState()
      const { user } = auth

      // dispatch(socketReceiveData(data))

      dispatch(statusThunk())

      if(user.isRoot || user.isAdmin || user.isHeadQuarter || user.isPartner) {

        let message = '알람이 도착했습니다'
        let link = '/'
        let alarmSound;
  
        switch(type){
          case "register": {
            message = '신규 회원가입이 확인됐습니다'
            link = '/member'
            alarmSound = config?.alarm?.register?.file ?? ''
            break;
          }
          case "depositRequest": {          
            message = '신규 입금요청이 확인됐습니다'
            link = '/deposit'
            alarmSound = config?.alarm?.depositRequest?.file ?? ''
            break;
          }
          case "withdrawalRequest": {
            message = '신규 출금요청이 확인됐습니다'
            link = '/withdrawal'
            alarmSound = config?.alarm?.withdrawalRequest?.file ?? ''
            break;
          }
          case "helpCenter": {          
            message = '신규 문의가 확인됐습니다'
            link = '/help-center'
            alarmSound = config?.alarm?.helpCenter?.file ?? ''
            break;
          }
          case "cautionUserLogin": {                
            message = '주의 회원 로그인이 확인됐습니다'
            link = '/member'
            alarmSound = config?.alarm?.cautionUserLogin?.file ?? ''
            break;
          }
          case "duplicateIp": {                
            message = '중복 IP 로그인이 확인됐습니다'
            link = '/history?tab=loginAccess'
            alarmSound = config?.alarm?.duplicateIp?.file ?? ''
            break;
          }
          case "adjustmentRequest": {
            message = '신규 정산요청이 확인됐습니다'
            // link = '/history?tab=loginAccess'
            alarmSound = config?.alarm?.adjustmentRequest?.file ?? ''
            break;
          }
          case "payoutFailure": {
            message = '오류 배당이 확인됐습니다'
            // link = '/history?tab=loginAccess'
            alarmSound = config?.alarm?.payoutFailure?.file ?? ''
            break;
          }
          case "highBetting": {
            message = '고액 배팅이 확인됐습니다'
            link = '/bet?tab=list'
            alarmSound = config?.alarm?.highBetting?.file ?? ''
            break;
          }
          case "mail": {
            message = '신규 쪽지가 확인됐습니다'
            link = '/mail'
            break;
          }
          default: {
            break;
          }
        }

        if(alarmSound) {
          alarmSound = `${PUBLIC_URL}/${alarmSound}`
          await playAlarmSound(alarmSound)
        } else{
          await playAlarmSound(defaultAlarmSound)
        }
        toast(<EventAlarm link={link} message={message} />, {
          ...toastOptions,
          toastId: id ?? null
        })
        await speakText(message, 2.0, 0.8)
      }

      if(user.isPartner) {
        let message = '알람이 도착했습니다'
        let link = '/'
        let alarmSound = defaultAlarmSound
  
        switch(type){
          case "duplicateIp": {                
            message = '중복 IP 로그인이 확인됐습니다'
            link = '/history?tab=loginAccess'
            alarmSound = config?.alarm?.duplicateIp?.file ?? ''
            alarmSound = alarmSound ? `${PUBLIC_URL}/${alarmSound}` : defaultAlarmSound
      
            toast(<EventAlarm link={link} message={message} />, {
              ...toastOptions,
              toastId: id ?? null
            })
            await playAlarmSound(alarmSound)
            await speakText(message, 2.0, 0.8)
            break;
          }
          default: {
            break;
          }
        }

      }
    })
  }
}

// Thunk action to disconnect from the Socket.IO server
export const disconnectSocket = () => {
  return dispatch => {
    socket.disconnect()
    dispatch(socketDisconnect())
  }
}
