import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Input,
  Button,
} from "reactstrap";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import {
  validateCaptcha,
  loadCaptchaEnginge,
  LoadCanvasTemplate,
} from "react-simple-captcha";
import { isAuthSelector } from "features/auth/authSelectors";
import { showAlert } from "utils/toast";
import { toast } from "react-toastify";
import SliderCaptcha from "@slider-captcha/react";
import urlJoin from "url-join";
import { getApiUrl } from "libs/functions";

window.Buffer = window.Buffer || require("buffer").Buffer;

const BASE_URL = "/admin/auth";

const Login = () => {
  const domainName = window.location.hostname;
  const { onLogin, handleAutoLogin } = useAuth();
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const isAuthenticated = useSelector(isAuthSelector);
  const [useCaptha, setUseCaptha] = useState(true);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [captcha, setCaptcha] = useState("");
  const [robotCheck, setRobotCheck] = useState(false);

  //read query params from url
  const tabParam = new URLSearchParams(search).get("session");

  const handleVerify = () => {
    setRobotCheck(true);
  };

  useEffect(() => {
    if (tabParam) {
      handleAutoLogin(tabParam);
    }
  }, [tabParam, handleAutoLogin]);

  useEffect(() => {
    const excludes = ['localhost', 'admin.sky-3337.com', 'admin.lon8989.com', 'admin.smf-009.com'];
    if (excludes.includes(domainName)) {
      setUseCaptha(false);
    }
  }, [domainName]);

  const handleLogin = (event) => {
    event.preventDefault();

    if (valusernameateInput()) {
      onLogin({ username, password });
    }
  };

  const valusernameateInput = () => {
    if (!username) {
      showAlert("아이디를 입력하세요");
      return false;
    }

    if (!password) {
      showAlert("비밀번호를 입력하세요");
      return false;
    }

    if (useCaptha &&  !robotCheck) {
      alert("캡차를 진행해주세요");
      // setCaptcha("");
      return false;
    }

    return true;
  };

  useEffect(() => {
    // toast.dismiss()
    // loadCaptchaEnginge(6);
  }, []);

  useEffect(() => {
    if (isAuthenticated) navigate("/", { replace: true });
  }, []);

  return (
    <section
      className="login d-flex align-items-center justify-content-center"
      style={{
        backgroundImage: `url('https://partner.demo-k.com/template/assets/images/bg.jpg')`,
        backgroundSize: "cover",
        minHeight: "100vh",
      }}
    >
      <Container>
        <Row>
          <Col sm="12" md={{ size: 6, offset: 3 }}>
            <Card className="login-card">
              <CardBody>
                <form name="loginFrm" onSubmit={handleLogin}>
                  <div className="auth-box">
                    <h3 className="text-center mb-4">로그인</h3>
                    <FormGroup>
                      <Input
                        type="text"
                        name="login_username"
                        placeholder="Your Id"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Input
                        type="password"
                        name="login_pwd"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </FormGroup>
                    {
                      useCaptha && 
                      <FormGroup>
                      <SliderCaptcha
                        variant="dark"
                        text={{
                          anchor: "로봇이 아닙니다",
                          challenge: "슬라이드하여 퍼즐을 맞추세요",
                        }}
                        create={urlJoin(
                          `${getApiUrl()}${BASE_URL}`,
                          "captcha/create"
                        )}
                        verify={urlJoin(
                          `${getApiUrl()}${BASE_URL}`,
                          "captcha/verify"
                        )}
                        callback={handleVerify}
                      />
                    </FormGroup>
                    }
                    
                    

                    {/* <FormGroup>
                      <LoadCanvasTemplate reloadText="새로고침" />
                    </FormGroup>
                    <FormGroup>
                      <Input
                        type="text"
                        name="login_code"
                        placeholder="보안 코드"
                        username="authcode"
                        value={captcha}
                        onChange={(e) => setCaptcha(e.target.value)}
                      />
                    </FormGroup> */}
                    <Button color="primary" size="md" block>
                      로그인
                    </Button>
                  </div>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Login;
