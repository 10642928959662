import { Howl } from 'howler';

// Play an alarm sound
export const playAlarmSound = (soundUrl) => {
  return new Promise((resolve, reject) => {
    const sound = new Howl({
      src: [soundUrl],
      html5: true, // Forces the use of HTML5 Audio, which supports `crossorigin`
      crossorigin: 'anonymous',
      onplay: () => resolve(),
      onloaderror: (id, err) => reject(err),
      onplayerror: (id, err) => reject(err),
    });
    sound.play();
  });
};

export const speakText = (text, rate = 1.0, pitch = 1.0) => {
  return new Promise((resolve, reject) => {
    const utterance = new SpeechSynthesisUtterance()

    // Set the text to convert to speech
    utterance.text = text

    // Set the speech rate and pitch
    utterance.rate = rate // Speech rate (0.1 to 10)
    utterance.pitch = pitch // Speech pitch (0 to 2)

    // Set the onend callback to resolve the Promise
    utterance.onend = resolve

    // Set the onerror callback to reject the Promise if there's an error
    utterance.onerror = reject

    // Speak the text
    window.speechSynthesis.speak(utterance)
  })
}
